import RfqLegalCopy from '@components/RfqLegalCopy/RfqLegalCopy';
import { useDetailedPricing } from '@hooks/use-detailed-pricing';
import useElementDimensionsObserver from '@hooks/useElementDimensionsObserver';
import { reportServerSideExperiment } from '@redux/experiments';
import { emailDomainSuggesterSelector } from '@redux/experiments/selectors/email-domain-suggester';
import { rfqGuestCountFormatSelector } from '@redux/experiments/selectors/rfq-guest-count-format';
import { rfqMessageAssistanceSelector } from '@redux/experiments/selectors/rfq-message-assistance';
import {
	isVendorWidgetDirectorySelector,
	vendorWidgetDirectorySelector,
} from '@redux/experiments/selectors/vendor-widget-directory';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { experiments } from '@settings';
import noop from '@utils/noop';
import { IconButton } from '@xo-union/tk-component-icons';
import { Caption, Subhead } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React, { type FC, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useStorefrontFeatureScannability } from '../../hooks/useStorefrontFeatureScannability';
import { VendorInfo } from '../bio/components/TeamInfo';
import Styles from './InlineRfq.scss';
import FormBody from './components/FormBody/FormBody';
import MessageAssistance from './components/FormBody/MessageAssistance/MessageAssistance';
import { HeaderSubText } from './components/HeaderSubText';
import LiteRFQ from './components/LiteRFQ/LiteRFQ';
import QuickResponderBadge from './components/QuickResponderBadge';
import SubmitCTA from './components/SubmitCTA';
import ViewConversation from './components/ViewConversation';
import { WhyUseKnot } from './components/WhyUseKnot';
import type { UseInlineRfqFormReturn } from './hooks/useInlineRfqForm/useInlineRfqForm';
import { useTrackInlineForm } from './hooks/useTrackInlineForm/use-track-inline-form';
import {
	checkIsTablet,
	isPostPrimary,
	loadStoredRfqFormData,
	resolveButtonText,
} from './utils';

type StateProps = ReturnType<typeof mapStateToProps>;

export interface OwnProps {
	context: UseInlineRfqFormReturn;
	isRfqMessageAssistanceVariant?: boolean;
	shouldRenderMessageAssistance?: boolean;
	setIsMessageAssistanceOpen: (isOpen: boolean) => void;
	handleSubmittedInlineRfq?: () => void;
}

type InlineRfqProps = StateProps & OwnProps;

const InlineRfq: FC<InlineRfqProps> = (props) => {
	const {
		conversations,
		vendorId = '',
		vendorRaw,
		context,
		isRfqMessageAssistanceVariant,
		shouldRenderMessageAssistance,
		setIsMessageAssistanceOpen,
		handleSubmittedInlineRfq,
		inlineFormFields,
		viewport,
		categoryCode,
		membership,
	} = props;

	const trackEditInlineForm = useTrackInlineForm('edit', context.initiator);
	const trackCloseInlineForm = useTrackInlineForm('close', context.initiator);
	const isTabletOrMedium = checkIsTablet(viewport);
	const { isMobile } = viewport;
	const isDesktop = !isMobile && !isTabletOrMedium;

	const isInDetailedPricing = useDetailedPricing();

	const emailDomainSuggesterVariant = useAppSelector(
		emailDomainSuggesterSelector,
	);
	const rfqGuestCountFormatVariant = useAppSelector(
		rfqGuestCountFormatSelector,
	);
	const rfqMessageAssistanceVariant = useAppSelector(
		rfqMessageAssistanceSelector,
	);

	const [generatedMessage, setGeneratedMessage] = useState('');

	const vendorWidgetDirectoryVariant = useAppSelector(
		vendorWidgetDirectorySelector,
	);
	// When experiment over, change this to isDirectory
	const isVendorWidgetDirectoryVariant = useAppSelector(
		isVendorWidgetDirectorySelector,
	);
	const shouldRemoveSubtitle =
		isVendorWidgetDirectoryVariant &&
		!['FLO', 'REC', 'WPH', 'VID'].includes(categoryCode);

	const dispatch = useAppDispatch();

	const shouldShowScannability = useStorefrontFeatureScannability();

	// Sticky CTA shadow handling
	const stickyCtaContainerRef = useRef<HTMLDivElement>(null);
	const { blockSize: stickyShadowOffset } = useElementDimensionsObserver(
		stickyCtaContainerRef,
	);

	const {
		isSubmitting,
		readyToSubmit,
		areErrorsInForm,
		shouldShowErrors,
		handleCtaClick,
		headerText,
		subheaderText,
		isModalOpen,
		setIsModalOpen,
		handleSubmit,
		values,
		pageType,
	} = context;

	useEffect(() => {
		if (conversations[vendorId] && isModalOpen) {
			setIsModalOpen(false);
		} else {
			loadStoredRfqFormData({ context, categoryCode, membership });
		}
	}, [isModalOpen]);

	useEffect(() => {
		if (emailDomainSuggesterVariant) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'Email domain suggester assignment: ',
				emailDomainSuggesterVariant,
			);
			dispatch(reportServerSideExperiment(experiments.emailDomainSuggester));
		}
	}, [emailDomainSuggesterVariant]);

	useEffect(() => {
		if (rfqGuestCountFormatVariant) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'rfqGuestCountFormat assignment: ',
				rfqGuestCountFormatVariant,
			);
			dispatch(reportServerSideExperiment(experiments.rfqGuestCountFormat));
		}
	}, [rfqGuestCountFormatVariant]);

	useEffect(() => {
		if (rfqMessageAssistanceVariant) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'rfqMessageAssistance assignment: ',
				rfqMessageAssistanceVariant,
			);
			dispatch(reportServerSideExperiment(experiments.rfqMessageAssistance));
		}
	}, [rfqMessageAssistanceVariant]);

	useEffect(() => {
		if (vendorWidgetDirectoryVariant) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'vendorWidgetDirectory assignment: ',
				vendorWidgetDirectoryVariant,
			);
			dispatch(reportServerSideExperiment(experiments.vendorWidgetDirectory));
		}
	}, [vendorWidgetDirectoryVariant]);

	useEffect(() => {
		if (!isSubmitting && readyToSubmit) {
			handleSubmit().then((submitted) => {
				if (submitted) {
					handleSubmittedInlineRfq?.();
				}
			});
		}
	}, [inlineFormFields]);

	const [initialMessageValue, setInitialMessageValue] = useState('');
	const [shouldHideLiteRFQ, setShouldHideLiteRFQ] = useState(false);

	const areErrorsInLiteRFQ = () =>
		values.firstName.error !== '' ||
		values.lastName.error !== '' ||
		values.emailAddress.error !== '' ||
		values.weddingDate.error !== '' ||
		values.guestCount.error !== '';

	const areLiteRfqFieldsFilled = () =>
		values.firstName.value !== '' &&
		values.lastName.value !== '' &&
		values.emailAddress.value !== '' &&
		values.weddingDate.value !== '' &&
		values.guestCount.value !== '';

	const shouldRenderLiteRFQ = useMemo(() => {
		return (
			// T&Cs need to be accepted
			(membership.member || // existing logged in user or new logged in user (lead auto-account-creation)
				!!Object.keys(conversations).length) && // existing logged out user after sending at least 1 lead
			areLiteRfqFieldsFilled() &&
			!areErrorsInLiteRFQ() &&
			!shouldHideLiteRFQ // when edit button was clicked on the Lite RFQ
		);
	}, [membership.member, conversations, values, shouldHideLiteRFQ]);

	const shouldDisplayVendorWidget = useMemo(() => {
		const hasBioContent =
			vendorRaw?.bio?.name &&
			vendorRaw?.bio?.displayRole &&
			vendorRaw?.bio?.photo?.url;
		const shouldDisplayOnModals =
			isModalOpen &&
			(pageType === 'storefront' || isVendorWidgetDirectoryVariant);
		const shouldDisplayOnInlineRfq = !isModalOpen;

		return hasBioContent && (shouldDisplayOnModals || shouldDisplayOnInlineRfq);
	}, [vendorRaw?.bio, pageType, isModalOpen, isVendorWidgetDirectoryVariant]);

	const shouldRenderQuickResponderBadge = Boolean(
		isDesktop &&
			vendorRaw?.vendorBehavior?.quickResponder &&
			(!shouldDisplayVendorWidget || isVendorWidgetDirectoryVariant),
	);

	if (
		isDesktop &&
		isPostPrimary(
			conversations,
			vendorId,
			isModalOpen,
			shouldShowErrors,
			areErrorsInForm,
		)
	) {
		return <ViewConversation />;
	}

	const handleCloseModal = () => {
		trackCloseInlineForm();
		setIsModalOpen(false);
	};

	return (
		<>
			<div
				className={classNames({
					[Styles.mobileWrapper]:
						isMobile && !isTabletOrMedium && !shouldRenderMessageAssistance,
					[Styles.tabletWrapper]: isTabletOrMedium,
					[Styles.inlineSticky]: isDesktop && !isModalOpen,
				})}
				data-detailed-pricing-rfq={isInDetailedPricing}
				data-scannability={shouldShowScannability}
			>
				<div
					className={classNames({
						[Styles.inlineWrapper]: isDesktop && !isModalOpen,
					})}
				>
					{shouldRenderMessageAssistance ? (
						<MessageAssistance
							context={context}
							setGeneratedMessage={setGeneratedMessage}
							generatedMessage={generatedMessage}
							isModalOpen={isModalOpen}
							isModalHorizontal={isModalOpen && isDesktop}
							closeMessageAssistance={() => setIsMessageAssistanceOpen(false)}
							initialMessageValue={initialMessageValue}
							setInitialMessageValue={setInitialMessageValue}
						/>
					) : (
						<>
							<div
								className={classNames({
									[Styles.basePadding]: isDesktop && !isModalOpen,
								})}
							>
								{isTabletOrMedium && (
									<div
										className={classNames({
											[Styles.headerCloseButton]: isTabletOrMedium,
										})}
									>
										<IconButton
											aria-label="close"
											block
											name="close"
											onClick={handleCloseModal}
											size="sm"
										/>
									</div>
								)}
								<div className={Styles.headerTextWrapper}>
									<Subhead bold>{headerText || 'Message vendor'}</Subhead>
									<Caption className={Styles.requiredText}>*=Required</Caption>
								</div>
								{!shouldRemoveSubtitle && (
									<HeaderSubText
										isMobile={isMobile}
										isTabletOrMedium={isTabletOrMedium}
										pricingText={subheaderText}
									/>
								)}
								{shouldDisplayVendorWidget && (
									<VendorInfo
										bio={vendorRaw?.bio}
										isInLiteRFQ={true}
										includeQuickResponderBadge={Boolean(
											vendorRaw?.vendorBehavior?.quickResponder,
										)}
										isVendorWidgetDirectoryVariant={
											isVendorWidgetDirectoryVariant
										}
										vendorName={vendorRaw?.name}
									/>
								)}
								{!shouldRenderLiteRFQ && (
									<FormBody
										context={context}
										generatedMessage={generatedMessage}
										setGeneratedMessage={setGeneratedMessage}
										setInitialMessageValue={setInitialMessageValue}
										openMessageAssistance={
											isRfqMessageAssistanceVariant
												? () => setIsMessageAssistanceOpen(true)
												: noop
										}
									/>
								)}
								{shouldRenderLiteRFQ && (
									<LiteRFQ
										context={context}
										generatedMessage={generatedMessage}
										setGeneratedMessage={setGeneratedMessage}
										setInitialMessageValue={setInitialMessageValue}
										buttonHandler={() => {
											trackEditInlineForm();
											setShouldHideLiteRFQ(true);
										}}
										openMessageAssistance={
											isRfqMessageAssistanceVariant
												? () => setIsMessageAssistanceOpen(true)
												: noop
										}
									/>
								)}
								{!shouldRenderLiteRFQ && (
									<div className={Styles.avoidStickyCover}>
										<WhyUseKnot />
									</div>
								)}
							</div>
							{isDesktop && isModalOpen && (
								<>
									<div className={Styles.stickyShadowCover} />
									<div
										style={{
											bottom: `${stickyShadowOffset}px`,
										}}
										className={Styles.stickyShadow}
									/>
								</>
							)}
							<div
								className={classNames({
									[Styles.stickyCta]: isMobile,
									[Styles.desktopCta]: isDesktop && !isModalOpen,
									[Styles.stickyDesktopCta]: isDesktop && isModalOpen,
								})}
								ref={stickyCtaContainerRef}
							>
								{!membership.member && (
									<div
										className={
											isModalOpen
												? Styles.legalCopyContainerModal
												: Styles.legalCopyContainer
										}
									>
										<RfqLegalCopy
											btnText={resolveButtonText({
												catCode: categoryCode,
												isMobile,
												isTablet: isTabletOrMedium,
											})}
										/>
									</div>
								)}
								<SubmitCTA handleSubmit={handleCtaClick} />
								{shouldRenderQuickResponderBadge && (
									<div className={Styles.quickResponderBadgeWrapper}>
										<QuickResponderBadge
											small={shouldShowScannability}
											isVendorWidgetDirectoryVariant={
												isVendorWidgetDirectoryVariant
											}
										/>
									</div>
								)}
								{membership.member && (
									<div className={Styles.termsContainer}>
										<a
											href="https://www.theknotww.com/terms-of-use"
											rel="noopener noreferrer"
											target="_blank"
											className={Styles.termsLink}
										>
											Terms of Use
										</a>
										<a
											href="https://www.theknotww.com/privacy-policy"
											rel="noopener noreferrer"
											target="_blank"
											className={Styles.termsLink}
										>
											Privacy Policy
										</a>
									</div>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	inlineFormFields: state.rfq.inline.fields,
	categoryCode: state.category.code,
	membership: state.membership,
	conversations: state.messagedVendors.conversations,
	vendorRaw: state.vendor.vendorRaw,
	vendorId: state.vendor.vendorRaw?.id,
	viewport: state.viewport,
});

export default connect(mapStateToProps)(InlineRfq);
